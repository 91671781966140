<!--
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-09-10 09:43:36
 * @LastEditTime: 2019-09-29 14:49:19
 * @LastEditors: Please set LastEditors
 -->
<template>
    <div class="login">
        <p>
            <input type="number" @keyup.enter="denglu()" v-model="phone" placeholder="请输入手机号">
            <i class="el-icon-error" @click="phone=''"></i>
        </p>
        <p>
            <input :type="look?'type':'password'" @keyup.enter="denglu()" v-model="pwd" placeholder="请输入密码">
            <img class="yanjing" @click="look=!look" :src="look?require('../../assets/eye.png'):require('../../assets/eye-no.png')" alt="">
        </p>
        <p @click="denglu">登录</p>
        <p @click="jizhu=!jizhu">
            <img :src="jizhu?require('../../assets/okblue.png'):require('../../assets/noccc.png')" alt="">
            记住账号
        </p>
        <p style="font-size: 20px; height: 20px; margin-bottom: 10px; color: #666;">北京经管智控科技有限公司</p>
        <p style="font-size: 14px; height: 20px; margin-bottom: 10px; color: #999;"><a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank">京ICP备20013780号-1</a></p>
        <p style="font-size: 14px; height: 20px; color: #999;"><a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010802033091" target="_blank"><img src="@/assets/gongan.png" target="_blank /" style="margin-bottom: -5px;">京公网安备 11010802033091号</a></p>
        
    </div>
</template>

<script>
import { insert_m_login_log, trigger_check_num, pwd_login, get_my_info, get_rel_list } from '../../api/api.js'
import { trigger_backup_num } from '../../api/noapi.js'
export default {
  name: 'login',
  data () {
    return {
      jizhu: true, // 是否记住,
      look: false,
      phone: '',
      pwd: ''
    }
  },
  mounted () {
  },
  created () {
    // location.reload()
    this.jichu()
  },
  mounted () {
  },
  watch: {
        
  },
  // computed: {
  //     asdas() {
  //         return this.$store.state.biaodan_list;
  //     }
  // },
  methods: {
    // ceshi(){
    //     this.$store.dispatch('shiyong_biaodan_danju_s',[{asdas:123,asd:22},{asdas:123,asd:22}])
    //     console.log(this.asdas)
    // },
    jichu () {
      this.phone = this.$bendi_naqu_cunchu('phone_pc', '永久')// phone_pc
    },
    denglu () {
      if (this.phone.length != 0 && this.pwd.length != 0) {
        if (/^1[3456789]\d{9}$/.test(this.phone) && this.pwd.length >= 6) {
          pwd_login({
            data: {
              mobile: this.phone,
              pwd: this.pwd
            }
          }).then(res => {
            console.log(res)
            if (res.data.code == 10010) {
              sessionStorage.setItem('pc_mlbb_xinxi', JSON.stringify(res.data.body.data))
              if (this.jizhu) {
                localStorage.setItem('phone_pc', JSON.stringify(this.phone))
              } else {
                localStorage.setItem('phone_pc', '')
              }
              // 获取我的基础信息
              get_my_info({
                data: {
                  user_id: JSON.parse(res.data.body.data).user_id
                }
              }).then(ras => {
                console.log(ras)
                if (ras.data.code == 10069) {
                  sessionStorage.setItem('pc_mlbb_jichuxinxi', JSON.stringify(ras.data.body.data))
                  get_rel_list({
                    data: {
                      user_id: JSON.parse(res.data.body.data).user_id
                    }
                  }).then(rbs => {
                    if (rbs.data.code == 10091) {
                      const qiye = JSON.parse(rbs.data.body.data)
                      if (qiye.ent_flag != undefined) {
                        sessionStorage.setItem('pc_mlbb_ent_flag', JSON.stringify(qiye.ent_flag))
                      }
                      if (qiye.staff_info != undefined) {
                        sessionStorage.setItem('pc_mlbb_ent_info', JSON.stringify(qiye.staff_info))
                      }
                      if (qiye.rel_list != undefined) {
                        sessionStorage.setItem('pc_mlbb_quanxian', JSON.stringify(qiye.rel_list))
                      } else {
                        sessionStorage.setItem('pc_mlbb_quanxian', JSON.stringify([]))
                      }
                      sessionStorage.setItem('pc_zkkj_time', JSON.stringify(0))
                      sessionStorage.setItem('lastTime', new Date().getTime())
                      console.log(res.data.body.data)
                      // trigger_backup_num({data:{
                      //     token:JSON.parse(res.data.body.data).client_login_token,
                      //     user_id:JSON.parse(ras.data.body.data).user_id,
                      //     mobile:this.phone
                      // }}).then(rus=>{
                      //     console.log(rus)
                      // })
                                            
                      // trigger_check_num({data:{
                      //     token:JSON.parse(res.data.body.data).client_login_token,
                      //     user_id:JSON.parse(ras.data.body.data).user_id,
                      // }}).then(rus=>{
                      //     console.log(rus)
                      // })
                      if (qiye.staff_info != undefined) {
                        insert_m_login_log({
                          data: {
                            ent_id: qiye.staff_info.ent_id,
                            mobile: this.phone
                          }
                        }).then(rzhi => {
                          this.$message({
                            message: '登录成功',
                            type: 'success'
                          })
                          this.$router.push('/home')
                          location.reload()
                        })
                      } else {
                        this.$message({
                          message: '登录成功',
                          type: 'success'
                        })
                        this.$router.push('/home')
                        location.reload()
                      }
                    } else if (rbs.data.code == 10092) {}
                  })
                } else if (ras.data.code == 10070) {
                }
              })
            } else if (res.data.code == 10011) {
              this.$message({
                message: '登录失败',
                type: 'error'
              })
            } else if (res.data.code == 10008) {
              this.$message({
                message: '您未注册',
                type: 'warning'
              })
            } else if (res.data.code == 10012) {
              this.$message({
                message: '密码错误或密码与用户名不匹配',
                type: 'error'
              })
            } else if (res.data.code == 11111) {
              this.$message({
                message: '已在别处登录或您上次非正常退出，请在一分钟后重新登录',
                type: 'error'
              })
            }
          })
        } else {
          this.$message({
            message: '手机号或密码填写格式不正确',
            type: 'warning'
          })
        }
      } else {
        this.$message({
          message: '请您填写完整信息',
          type: 'warning'
        })
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
 @import './denglu.scss';
</style>
